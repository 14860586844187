// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-beasts-adventure-js": () => import("./../src/pages/posts/beasts-adventure.js" /* webpackChunkName: "component---src-pages-posts-beasts-adventure-js" */),
  "component---src-pages-posts-ready-uppy-js": () => import("./../src/pages/posts/ready-uppy.js" /* webpackChunkName: "component---src-pages-posts-ready-uppy-js" */),
  "component---src-pages-posts-remote-in-windsor-js": () => import("./../src/pages/posts/remote-in-windsor.js" /* webpackChunkName: "component---src-pages-posts-remote-in-windsor-js" */),
  "component---src-pages-presentations-js": () => import("./../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-visualizations-js": () => import("./../src/pages/visualizations.js" /* webpackChunkName: "component---src-pages-visualizations-js" */)
}

